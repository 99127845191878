import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@reach/tabs"
import "@reach/tabs/styles.css"
import dayjs from "dayjs"
import { graphql, useStaticQuery } from "gatsby"
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

library.add(fas)

export default function Basin() {
  const { t } = useTranslation()
  const { language } = useI18next()

  const data = useStaticQuery(graphql`
    query BasinPageQuery {
      results: allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "basin" } } }
        sort: { order: DESC, fields: frontmatter___datetime }
      ) {
        edges {
          node {
            frontmatter {
              title
              title_en
              content
              content_en
              datetime
              templateKey
              headerImage
            }
            fields {
              slug
            }
          }
        }
      }
      videos: allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "basin-video" } } }
        sort: { order: ASC, fields: frontmatter___order }
      ) {
        edges {
          node {
            frontmatter {
              link
              datetime
              templateKey
              order
            }
          }
        }
      }
    }
  `)
  const posts = data.results.edges
    .filter(edge => !edge.node.frontmatter.title.startsWith("dummy__"))
    .map(edge => {
      const post = edge.node

      const title =
        language === "en" ? post.frontmatter.title_en : post.frontmatter.title
      const content =
        language === "en"
          ? post.frontmatter.content_en
          : post.frontmatter.content
      let summary = content?.slice(0, 400)
      summary = summary?.replace(
        /(\*\*)|(\\\s)|(__)|!\[[^\]]*\]\((.*?)\s*("(?:.*[^"])")?\s*\)|(?:__|[*#])|\[(.*?)\]\(.*?\)/gim,
        " "
      ) // strip markdown symbols (italics, bolds, links and images)

      if (language === "en") {
        dayjs.locale("en")
      } else {
        dayjs.locale("tr")
      }
      return (
        <div className="blog" key={post.frontmatter.title}>
          <img
            className="blog-img"
            src={post.frontmatter.headerImage || "/assets/img/blog/1.png"}
            alt="img"
          />
          <div className="blog-title">{title}</div>
          <div className="blog-preview">{summary}</div>
          <div className="publish-or-go">
            <div className="publish-date">
              {dayjs(post.frontmatter.datetime).format("D MMMM, YYYY")}
            </div>
            <Link to={post.fields.slug}>
              {t("devamini-oku")}
              <FontAwesomeIcon icon={["fas", "chevron-right"]} size="1x" />
            </Link>
          </div>
        </div>
      )
    })

  const videos = data.videos.edges.map(edge => {
    const video = edge.node
    const link = video.frontmatter.link
    const embedLink = link.replace("watch?v=", "embed/")
    return (
      <div className="blog video">
        <iframe
          width="100%"
          height="315"
          src={embedLink}
          title={video.frontmatter.link}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    )
  })

  return (
    <Layout>
      <SEO title={t("basin")} />
      <Tabs>
        <TabList>
          <Tab>{t("basin-haberler")}</Tab>
          <Tab>{t("basin-videolar")}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <div className="blog-container">{posts}</div>
          </TabPanel>
          <TabPanel>
            <div className="blog-container">{videos}</div>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Layout>
  )
}
